import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ce68af78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-text" }
const _hoisted_2 = { style: {"font-weight":"600"} }
const _hoisted_3 = { class: "confirmation-bullet" }
const _hoisted_4 = { class: "bullet-text" }
const _hoisted_5 = { class: "confirmation-bullet" }
const _hoisted_6 = { class: "bullet-text" }
const _hoisted_7 = { class: "confirmation-bullet" }
const _hoisted_8 = { class: "bullet-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseAlert = _resolveComponent("BaseAlert")!

  return (_openBlock(), _createBlock(_component_BaseAlert, {
    dangerAlert: true,
    icon: "bell",
    onConfirmAlert: _ctx.confirmTos,
    onCancelAlert: _ctx.redirectToProfile,
    wide: ""
  }, {
    header: _withCtx(() => [
      _createTextVNode("Important")
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.translate("gp_connect.tos_profile_popup_text_1")) + " " + _toDisplayString(_ctx.clientName) + ".", 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.translate("gp_connect.tos_profile_popup_text_2")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.translate("gp_connect.tos_profile_popup_text_3")), 1)
      ]),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.translate("gp_connect.tos_profile_popup_text_4")) + ":", 1),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.translate("gp_connect.tos_profile_popup_text_5")) + " ", 1),
              _createElementVNode("span", {
                class: "v-tos",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadNourishTosFile()))
              }, _toDisplayString(_ctx.translate("gp_connect.tos_profile_popup_text_5_link")), 1)
            ])
          ])
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("span", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.translate("gp_connect.tos_profile_popup_text_6")), 1)
          ])
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("span", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.translate("gp_connect.tos_profile_popup_text_7")), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onConfirmAlert", "onCancelAlert"]))
}