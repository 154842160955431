import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7a65dbfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "v-gp-connect-date-selector"
}
const _hoisted_2 = {
  key: 1,
  class: "v-no-date-filter-container"
}
const _hoisted_3 = { class: "v-no-date-filter-string" }
const _hoisted_4 = {
  key: 2,
  class: "v-date-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseDatePickerInput = _resolveComponent("BaseDatePickerInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.filterByDates)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_BaseDatePickerInput, {
            label: "From",
            boldLabel: "",
            labelWidth: "45px",
            modelValue: _ctx.startDate,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_BaseDatePickerInput, {
            label: "To",
            boldLabel: "",
            labelWidth: "45px",
            modelValue: _ctx.endDate,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endDate) = $event))
          }, null, 8, ["modelValue"]),
          _createElementVNode("button", {
            class: "v-clear-dates-btn",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleDateFilter()))
          }, _toDisplayString(_ctx.translate("gp_connect.no_date_filter")), 1)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.filterByDates)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.translate("gp_connect.display_all_results")), 1),
          (!_ctx.filterByDates)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "v-clear-dates-btn",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleDateFilter()))
              }, _toDisplayString(_ctx.translate("gp_connect.filter_by_date")), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.displayInvalidDate && _ctx.filterByDates)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", null, _toDisplayString(_ctx.translate("gp_connect.date_input_error")), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}