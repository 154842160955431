import IInteraction from '@/timeline/types/IInteraction';
import {
  InteractionLogTableData,
  InteractionLogTableDataEntry,
} from '@/timeline/types/InteractionLogData';
import use from '@/_shared/compositionApi';
import BaseTableHeader from '@/_shared/types/baseTableHeader';
import { format } from 'date-fns';
import filterOutEmpties from '@/timeline/services/table';
import { CoercedChoice, Parameter } from '@/timeline/types/Parameter';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import {
  buildParameterHeaders,
  getParamEntries,
  getValuesForParams,
} from '@/timeline/services/interactionParamsHelpers';
import { clientStore } from '@/_shared/store/clients';
import { computed } from 'vue';
import { personStore } from '@/_shared/store/people';
import { TableLink } from '../types/tableLink';

const { translate } = use.helpers();

export const fetchAllInteractionsResponsiblePepole = async (inters : IInteraction[]) => {
  // this is with a minimal footprint only people not yet in store are fetched
  const allResponsiblePersonIds = inters.reduce((acc, i) => {
    const ids = i.responsiblePersonIds?.length ? [...i.responsiblePersonIds] : [];
    if (i.originatorId) ids.push(i.originatorId);
    if (ids.length) acc.push(...ids);
    return acc;
  }, [] as number[]);
  if (allResponsiblePersonIds.length) await personStore.asyncByIds([...new Set(allResponsiblePersonIds)]);
};

const serviceLogTable = async (
  interactions: IInteraction[],
  showTotal = false,
  showDailyAvg = false,
  showTotalDailyAvg = false,
  forCarePlanConsent = false,
): Promise<InteractionLogTableData | null> => {
  await loadModuleLanguageFileAsync('timeline');

  if (!interactions.length) return null;

  if (interactions.some((interaction) => interaction.parameters?.length)) {
    const { clientId } = interactions[0];
    await getValuesForParams(interactions, clientId);
  }

  // todo, this is needed because composable is not reactive
  await fetchAllInteractionsResponsiblePepole(interactions);

  const [entries, headers] = filterOutEmpties(
    buildEntries(interactions, showTotal, showDailyAvg, showTotalDailyAvg, forCarePlanConsent),
    forCarePlanConsent ? buildCarePlanHeaders(interactions) : buildHeaders(interactions),
  );

  return {
    headers,
    entries,
  };
};

const buildCarePlanHeaders = (interactions: IInteraction[]) => {
  const instanceHeader: BaseTableHeader[] = buildInstanceHeader(interactions);

  const parameterHeaders: BaseTableHeader[] = buildParameterHeaders(interactions[0]);

  const headers: BaseTableHeader[] = [
    {
      keyName: 'agreementCapture',
      displayName: translate('timeline.interaction.interactionCapture', { care_plan_term: window.currentOrganisationUnit.care_plan_term }),
      type: 'link',
    },
    {
      keyName: 'finishAt',
      displayName: translate('timeline.interaction.finishedOn'),
      type: 'string',
    },
    ...instanceHeader,
    ...parameterHeaders,
  ];

  return headers;
};

const buildHeaders = (interactions: IInteraction[]) => {
  const instanceHeader: BaseTableHeader[] = buildInstanceHeader(interactions);

  const parameterHeaders: BaseTableHeader[] = buildParameterHeaders(interactions[0]);

  const headers: BaseTableHeader[] = [
    {
      keyName: 'finishAt',
      displayName: translate('timeline.interaction.finishAt'),
      type: 'string',
    },
    ...instanceHeader,
    ...parameterHeaders,
    {
      keyName: 'responsiblePeople',
      displayName: translate('timeline.interaction.responsiblePeople'),
      type: 'string',
    },
    {
      keyName: 'dailyNote',
      displayName: translate('timeline.interaction.notePublic'),
      type: 'string',
    },
    {
      keyName: 'handover',
      displayName: translate('timeline.interaction.handover'),
      type: 'string',
    },
  ];

  return headers;
};

const buildInstanceHeader = (interactions: IInteraction[]) => {
  const hasInstance = interactions.some((i) => i.nourishInstanceId);
  return hasInstance ? [{
    keyName: 'nourishInstanceReference',
    displayName: translate('timeline.interaction.nourishInstanceReference'),
    type: 'string',
  }] : [];
};

const buildEntries = (
  interactions: IInteraction[],
  showTotal: boolean,
  showDailyAvg: boolean,
  showTotalDailyAvg: boolean,
  forCarePlanConsent: boolean,
): InteractionLogTableDataEntry[] => {
  const entries = interactions.map((interaction) => {
    interaction.computedParameters = interaction.computedParameters?.filter((param) => param.id !== 'actions');
    const finishAt = format(new Date(interaction.finishAt as string), 'dd/MM/yyyy - HH:mm');

    const entry: InteractionLogTableDataEntry = {
      id: interaction.id as number,
      finishAt: finishAt as string,
      ...maybeAddInstanceReference(interaction),
      ...getParamEntries([...(interaction.parameters || []), ...(interaction.computedParameters || [])]),
    };

    maybeAddResponsiblePeople(interaction, entry);
    maybeAddNotePublic(interaction, entry);
    maybeAddHandover(interaction, entry);
    maybeAddLink(interaction, entry, forCarePlanConsent);

    return entry;
  });

  addTotalAndAverages(interactions, entries, showTotal, showDailyAvg, showTotalDailyAvg);

  return entries;
};

// TODO: Need to return the reference instead of instance id - can we get this from store?
const maybeAddInstanceReference = (interaction: IInteraction) => {
  const instances = clientStore.skinInstances(interaction.clientId);
  const instanceRef = computed(() => instances.value?.find((i) => i.id === interaction.nourishInstanceId)?.reference);
  return interaction.nourishInstanceId ? { nourishInstanceReference: instanceRef } : null;
};

const maybeAddResponsiblePeople = (interaction: IInteraction, entry: InteractionLogTableDataEntry) => {
  if (interaction.responsiblePersonIds?.length) {
    entry.responsiblePeople = personStore.names(interaction.responsiblePersonIds);
  }
};

const maybeAddNotePublic = (interaction: IInteraction, entry: InteractionLogTableDataEntry) => {
  if (interaction.notePublic) {
    entry.dailyNote = interaction.notePublic;
  }
};

const maybeAddHandover = (interaction: IInteraction, entry: InteractionLogTableDataEntry) => {
  entry.handover = interaction.handover ? 'Yes' : 'No';
};

const maybeAddLink = (interaction: IInteraction, entry: InteractionLogTableDataEntry, forCarePlanConsent: boolean) => {
  if (forCarePlanConsent) {
    entry.agreementCapture = {
      text: 'Link',
      bold: true,
      italic: true,
      to: { name: 'client.timeline.interaction', params: { clientId: interaction.clientId, id: interaction.id } },
    } as TableLink;
  }
};

const addTotalAndAverages = (interactions: IInteraction[], entries: InteractionLogTableDataEntry[], showTotal: boolean, showDailyAvg: boolean, showTotalDailyAvg: boolean) => {
  if (showTotal && interactions.length > 0) {
    entries.push(buildTotalRow(interactions));
  }
  if (showDailyAvg && interactions.length > 0) {
    entries.push(buildDailyAvgRow(interactions));
  }
  if (showTotalDailyAvg && interactions.length > 0) {
    entries.push(buildTotalDailyAvgRow(interactions));
  }
};

const buildTotalRow = (interactions: IInteraction[]): InteractionLogTableDataEntry => {
  const row: InteractionLogTableDataEntry = {
    id: 'total',
    finishAt: translate('log.total'),
  };
  // TODO: Do we need to get parameters from all interactions?
  interactions[0].parameters.forEach((parameter) => {
    let val: number | string = '';
    if (parameter.valueType === 'number') {
      val = calculateTotal(interactions, parameter);
    }

    row[parameter.id] = val;
  });

  // TODO: Do we need to get responsiblePersonIds from all interactions?
  addOtherCols(row, interactions[0]);

  return row;
};

const buildDailyAvgRow = (interactions: IInteraction[]): InteractionLogTableDataEntry => {
  const row: InteractionLogTableDataEntry = {
    id: 'average',
    finishAt: translate('log.average'),
  };

  const interactionsPerDay = groupInteractionsByDay(interactions);

  // TODO: Do we need to get parameters from all interactions?
  interactions[0].parameters.forEach((parameter) => {
    let val: number | string = '';
    if (parameter.valueType === 'number') {
      val = calculateAvgPerDay(interactionsPerDay, parameter, false);
    }

    row[parameter.id] = val;
  });

  addOtherCols(row, interactions[0]);

  return row;
};

const buildTotalDailyAvgRow = (interactions: IInteraction[]): InteractionLogTableDataEntry => {
  const row: InteractionLogTableDataEntry = {
    id: 'totalDailyAverage',
    finishAt: translate('log.totalDailyAverage'),
  };

  const interactionsPerDay = groupInteractionsByDay(interactions);

  interactions[0].parameters.forEach((parameter) => {
    let val: number | string = '';
    if (parameter.valueType === 'number') {
      val = calculateAvgPerDay(interactionsPerDay, parameter, true);
    }

    row[parameter.id] = val;
  });

  addOtherCols(row, interactions[0]);

  return row;
};

const calculateTotal = (interactions: IInteraction[], parameter: Parameter) => interactions.map((interaction) => {
  const interactionParameter = interaction.parameters.find((p) => p.id === parameter.id);
  if (!interactionParameter) { return 0; }
  const coercedChoiceValue = (interactionParameter.coercedValue as CoercedChoice)?.value;
  if (coercedChoiceValue) { return coercedChoiceValue as number; }
  return interactionParameter.coercedValue as number;
}).reduce((sum, value) => sum + value, 0);

const calculateAvgPerDay = (interactionGroups: IInteraction[][], parameter: Parameter, total: boolean) => {
  const days: number[][] = [];

  interactionGroups.forEach((group) => {
    const day: number[] = [];
    group.forEach((interaction) => {
      const param = interaction.parameters.find((p) => p.id === parameter.id);
      const value = param && param.coercedValue;
      if (typeof value === 'number' && !Number.isNaN(value) && value !== null) {
        day.push(value);
      }
    });
    days.push(day);
  });

  const avgTotals = days.reduce((average, valueGroup) => {
    if (valueGroup.length === 0) { return average; }
    return average + valueGroup.reduce((avg, value) => (total ? avg + value : avg + value / valueGroup.length), 0);
  }, 0);

  return (avgTotals / days.length).toFixed(2);
};

const groupInteractionsByDay = (interactions: IInteraction[]): IInteraction[][] => Object.values(interactions.reduce((obj: Record<string, IInteraction[]>, interaction) => {
  const groupKey = format(new Date(interaction.finishAt as string), 'dd/MM/yyyy');
  if (!obj[groupKey]) {
    obj[groupKey] = [];
  }
  obj[groupKey].push(interaction);
  return obj;
}, {}));

const addOtherCols = (row: InteractionLogTableDataEntry, interaction: IInteraction) => {
  // TODO: Do we need to get responsiblePersonIds from all interactions?
  if (interaction.responsiblePersonIds?.length) {
    row.responsiblePeople = '';
  }
  if (interaction.notePublic?.length) {
    row.dailyNote = '';
  }

  row.handover = '';

  return row;
};

export default serviceLogTable;
