
import { defineComponent } from 'vue';
import use from '@/_shared/compositionApi';
import BaseAlert from '@/_shared/components/feedback/BaseAlert.vue';
import { fetchGpConnectTosFile } from '../services/GpConnectApi';
import { redirectToProfile } from '../../helpers/medicalRecordFunctions';

export default defineComponent({
  components: {
    // BaseCheckbox,
    BaseAlert,
  },

  setup(props, context) {
    const { translate } = use.helpers();

    // const confirmationOne = ref(false)
    // const confirmationTwo = ref(false)
    // const confirmationThree = ref(false)

    // const valid = computed(() => {
    //   return confirmationOne.value && confirmationTwo.value && confirmationThree.value
    // })

    // Cuts the end off of the URL to redirect to the client profile
    function getProfileUrl() {
      const url = window.location.href;
      const urlSegments = url.split('/');
      const urlProfileSegments = urlSegments.slice(0, 7);
      return urlProfileSegments.join('/');
    }

    function downloadNourishTosFile() {
      fetchGpConnectTosFile();
    }

    function confirmTos() {
      window.localStorage.setItem('v-gp-connect-tos-confirmed', 'true');
      context.emit('confirmTos', true);
    }

    return {
      translate,
      getProfileUrl,
      confirmTos,
      redirectToProfile,
      // confirmationOne,
      // confirmationTwo,
      // confirmationThree,
      // valid,
      downloadNourishTosFile,
    };
  },
  props: {
    clientName: {
      type: String,
      required: true,
    },
  },
  emits: ['confirmTos'],
});
