import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2f5073d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "v-pagination-container"
}
const _hoisted_2 = {
  key: 2,
  disabled: true,
  class: "v-pagination-button"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 3,
  disabled: true,
  class: "v-pagination-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.totalPages > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.currentPage > 1)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prev && _ctx.prev(...args))),
              class: "v-pagination-button v-previous"
            }, _toDisplayString(_ctx.translate('pagination.previous_label')), 1))
          : _createCommentVNode("", true),
        (_ctx.currentPage > 10 || _ctx.totalPages === 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPage(1))),
              class: _normalizeClass(["v-pagination-button v-pages", 1 === _ctx.currentPage ? 'v-selected-page' : ''])
            }, " 1 ", 2))
          : _createCommentVNode("", true),
        (_ctx.extraPagesToLeft())
          ? (_openBlock(), _createElementBlock("button", _hoisted_2, " ... "))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalPages, (page) => {
          return (_openBlock(), _createElementBlock("div", { key: page }, [
            (_ctx.showPage(page))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: ($event: any) => (_ctx.setPage(page)),
                  class: _normalizeClass(["v-pagination-button v-pages", page === _ctx.currentPage ? 'v-selected-page' : ''])
                }, [
                  _createElementVNode("span", null, _toDisplayString(page), 1)
                ], 10, _hoisted_3))
              : _createCommentVNode("", true)
          ]))
        }), 128)),
        (_ctx.extraPagesToRight())
          ? (_openBlock(), _createElementBlock("button", _hoisted_4, " ... "))
          : _createCommentVNode("", true),
        (_ctx.totalPages > 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 4,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPage(_ctx.totalPages))),
              class: _normalizeClass(["v-pagination-button v-pages", _ctx.totalPages === _ctx.currentPage ? 'v-selected-page' : ''])
            }, _toDisplayString(_ctx.totalPages), 3))
          : _createCommentVNode("", true),
        ((_ctx.currentPage) < _ctx.totalPages)
          ? (_openBlock(), _createElementBlock("button", {
              key: 5,
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args))),
              class: "v-pagination-button v-next"
            }, _toDisplayString(_ctx.translate('pagination.next_label')), 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}