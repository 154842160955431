
import { storeToRefs } from 'pinia';
import {
  computed, defineComponent, onBeforeMount, PropType, ref,
} from 'vue';
import format from 'date-fns/format';
import useUserStore from '@/_shared/store/user';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { toast, ToastType } from '@/_shared/services/nourishHelpers';
import useIntegrationStore from '@/_shared/store/integrations';
import { BaseIcon, BaseToggle } from '@/_shared/components';
import Integration from '../types/integration';
import IntegrationDisableModal from './modals/IntegrationDisableModal.vue';
import IntegrationInformationModal from './modals/IntegrationInformationModal.vue';
import IntegrationEnableModal from './modals/IntegrationEnableModal.vue';
import IntegrationNhsAsidModal from './modals/IntegrationNhsAsidModal.vue';
import LondonCareRecordProviderDetail from '../../medical_record_integrations/london_care_record/components/LondonCareRecordProviderDetail.vue';

export default defineComponent({
  components: {
    IntegrationDisableModal,
    IntegrationInformationModal,
    IntegrationEnableModal,
    IntegrationNhsAsidModal,
    LondonCareRecordProviderDetail,
    BaseIcon,
    BaseToggle,
  },
  props: {
    integration: {
      type: Object as PropType<Integration>,
      required: true,
    },
    ou: {
      type: Object as PropType<OrganisationUnit>,
      required: true,
    },
  },
  emits: ['ouToggled'],
  setup(props, context) {
    const { currentUser } = storeToRefs(useUserStore());
    const { translate } = use.helpers();
    const showDisableAlert = ref<boolean>(false);
    const showInfoModal = ref<boolean>(false);
    const showEnableIntegrationModal = ref<boolean>(false);
    const tokenModalOnly = ref<boolean>(false);
    // TODO get from nourishstore
    const showInfoButton = currentUser.value.carer?.superuser;
    const showAsidModal = ref<boolean>(false);
    const token = ref<string>('');
    const integrationsStore = useIntegrationStore();
    const currentCollection = ref<Integration | null>();

    onBeforeMount(async () => {
      await loadModuleLanguageFileAsync('integrations'); // TODO WHEN PURE VUE remove
      currentCollection.value = getCurrentCollection();
    });

    const getCurrentCollection = () => {
      if (isAdvancedCollection.value) {
        return allAdvancedCollections.filter((collection) => props.ou.id === collection.orgUnitId
          && props.integration.advancedCollectionCodename === collection.advancedCollectionCodename)[0];
      }
      return null;
    };

    const ouIntegrationToggled = () => {
      if (isAdvancedCollection.value) {
        toggleAdvancedCollection();
      } else if (props.integration.integrationCodename && props.ou.id && integrationsStore.isOuToggleEnabled(props.integration.integrationCodename, props.ou.id) === true) {
        showDisableAlert.value = true;
      } else {
        tokenModalOnly.value = false;
        showEnableIntegrationModal.value = true;
      }
    };

    const toggleAdvancedCollection = async () => {
      if (currentCollection.value) {
        if (currentCollection.value.enabled) {
          toast(translate('integrations.advanced_collection_disabled'), ToastType.Success);
          await integrationsStore.unsubscribeOuFromAdvancedCollection(currentCollection.value as Integration, props.ou);
        } else {
          const toastText = `${translate('integrations.advanced_collection_enabled')}${currentCollection.value?.name}${translate('integrations.advanced_collection_enabled_2')}`;
          toast(toastText, ToastType.Info);
          await integrationsStore.subscribeOuToAdvancedCollection(currentCollection.value as Integration, props.ou);
        }
      }
    };

    const updatedBy = computed(() => {
      if (props.integration.integrationCodename && props.ou.id) {
        const integration = integrationsStore.getCustomerIntegrationForOu(props.integration.integrationCodename, props.ou.id);
        if (integration !== undefined) {
          return integration.lastChangedBy;
        }
      }
      return '';
    });

    const updatedAt = computed(() => {
      if (props.integration.integrationCodename && props.ou.id) {
        const integration = integrationsStore.getCustomerIntegrationForOu(props.integration.integrationCodename, props.ou.id);
        if (integration !== undefined) {
          const { lastChangedAt } = integration;
          if (lastChangedAt) {
            return format(Date.parse(lastChangedAt), 'h:mm a - dd/MM/yyyy');
          }
        }
      }
      return '';
    });

    const closeDisableAlert = () => {
      showDisableAlert.value = false;
    };

    const disableOuIntegration = async () => {
      showDisableAlert.value = false;
      if (props.ou.id && customerIntegrationForOu.value !== undefined) {
        await integrationsStore.deleteOuIntegration(props.integration, customerIntegrationForOu.value, props.ou.id);
      }
    };

    const showTokenOrToggleIntegration = () => {
      if (props.integration.integrationCodename && props.ou.id && integrationsStore.isOuToggleEnabled(props.integration.integrationCodename, props.ou.id) === true) {
        tokenModalOnly.value = true;
      }
      showEnableIntegrationModal.value = true;
    };

    const toggleOnExtension = (tokenValue: string) => {
      context.emit('ouToggled');
      token.value = tokenValue;
      showEnableIntegrationModal.value = false;
    };

    const toggleOnNhsExtension = (tokenValue: string) => {
      toggleOnExtension(tokenValue);
      showAsidModal.value = true;
    };

    const customerIntegrationForOu = computed(() => {
      if (props.integration.integrationCodename && props.ou.id) {
        return integrationsStore.getCustomerIntegrationForOu(props.integration.integrationCodename, props.ou.id);
      }
      return undefined;
    });

    const allAdvancedCollections: Integration[] = integrationsStore.integrations.filter((integration) => !!integration.advancedCollectionCodename);

    const shouldShowOu = computed(() => {
      if (props.integration.advancedCollectionCodename) {
        return allAdvancedCollections.some((collection) => props.integration.advancedCollectionCodename === collection.advancedCollectionCodename
          && collection.orgUnitId === props.ou.id);
      }
      return true;
    });

    const isAdvancedCollection = computed(() => !!props.integration.advancedCollectionCodename);

    const isOuToggleEnabled = () => {
      if (isAdvancedCollection.value && currentCollection.value) {
        return currentCollection.value.enabled;
      }
      if (props.integration.integrationCodename) {
        return integrationsStore.isOuToggleEnabled(props.integration.integrationCodename!, props.ou.id!);
      }
      return false;
    };

    return {
      translate,
      ouIntegrationToggled,
      updatedAt,
      updatedBy,
      showDisableAlert,
      disableOuIntegration,
      closeDisableAlert,
      showInfoModal,
      showEnableIntegrationModal,
      showTokenOrToggleIntegration,
      tokenModalOnly,
      showInfoButton,
      toggleOnExtension,
      customerIntegrationForOu,
      toggleOnNhsExtension,
      showAsidModal,
      token,
      integrationsStore,
      isOuToggleEnabled,
      getCustomerIntegrationForOu: integrationsStore.getCustomerIntegrationForOu,
      shouldShowOu,
      isAdvancedCollection,
    };
  },
});
