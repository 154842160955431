import apiClient from '@/_shared/services/apiClient';
import nourishAuthStore from '@/_shared/store/userAuth';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import { ClientInformation } from '@/_shared/store/clients';
import { storeToRefs } from 'pinia';
import CustomerIntegration from '../types/customerIntegration';
import Integration from '../types/integration';

export const fetchIntegrations = async (orgId: number) => {
  const url = `organisations/${orgId}/integrations`;
  try {
    return await apiClient.get<Integration[]>(url, { params: { } });
  } catch (error) {
    return [] as Integration[];
  }
};

export const fetchAdvancedCollections = async (orgId: number) => {
  const url = `organisations/${orgId}/advanced_collections`;
  try {
    return await apiClient.get<Integration[]>(url, { params: { }, rootNode: 'collections' });
  } catch (error) {
    return [];
  }
};

export const fetchCustomerIntegrations = async (orgId: number) => {
  const url = `organisations/${orgId}/integrations/${orgId}`;
  try {
    return await apiClient.get<CustomerIntegration[]>(url, { params: { } });
  } catch (error) {
    return [] as CustomerIntegration[];
  }
};

export const deleteOrgIntegrationApi = async (integration: Integration, orgId: number) => {
  const url = `organisations/${orgId}/integrations/delete_org_integration/${integration.id}?integration_codename=${integration.integrationCodename}&integration_type=${integration.integrationType}`;
  try {
    return await apiClient.delete<CustomerIntegration[]>(url);
  } catch (error) {
    return [] as CustomerIntegration[];
  }
};

export const deleteOuIntegrationApi = async (integration: Integration, customerIntegration: CustomerIntegration, orgId: number, ouId: number) => {
  const url = `organisations/${orgId}/integrations/${customerIntegration.id}?integration_codename=${integration.integrationCodename}&integration_type=${integration.integrationType}&integration_id=${integration.id}&ou_id=${ouId}`;
  try {
    return await apiClient.delete<CustomerIntegration[]>(url);
  } catch (error) {
    return [] as CustomerIntegration[];
  }
};

export const fetchOrgUnitsForOrganisation = async (orgId: number) => {
  const url = `organisations/${orgId}/organisation_units`;
  try {
    return await apiClient.get<OrganisationUnit[]>(url, { params: { }, rootNode: 'organisationUnits' });
  } catch (error) {
    return [] as OrganisationUnit[];
  }
};

export const fetchClientInformationForOu = async (integration: Integration, ouId: number) => {
  const url = `organisation_units/${ouId}/clients/integration_info?integration_type=${integration.integrationType}&integration_codename=${integration.integrationCodename}&state=admitted`;
  try {
    return await apiClient.get<ClientInformation[]>(url, { params: { }, rootNode: 'clients' });
  } catch (error) {
    return [];
  }
};

export const postNewIntegrationForOu = async (integration: Integration, orgId: number, ouId: number, token: string) => {
  const url = `organisations/${orgId}/integrations/new`;
  const body = {
    integration: {
      integration_id: integration.id,
      integration_codename: integration.integrationCodename,
      org_unit_id: ouId,
      active: true,
      integration_token: token,
      tos: true,
      name: integration.name,
      integration_type: integration.integrationType,
    },
  };
  try {
    return await apiClient.post(url, body);
  } catch (error) {
    return [];
  }
};

export const updateIntegrationForOu = async (customerIntegration: CustomerIntegration) => {
  const url = `organisations/${customerIntegration.orgId}/integrations/edit_unit`;
  const body = {
    integration: {
      id: customerIntegration.id,
      integration_token: customerIntegration.integrationToken,
    },
  };
  try {
    return await apiClient.put(url, body);
  } catch (error) {
    return [];
  }
};

export const nhsOdsCodeLookup = async (odsCode: string) => {
  const url = `gp_connect/ods_valid/${odsCode}`;
  try {
    await apiClient.get<boolean[]>(url);
    return true;
  } catch (error) {
    return false;
  }
};

const NOURISH_DEMO_ODS_CODES = ['8KF32', 'A20047', 'B82617'];
export const nhsNdsaCheck = async (odsCode: string): Promise<boolean> => {
  if (NOURISH_DEMO_ODS_CODES.includes(odsCode)) {
    return true;
  }

  const url = `gp_connect/ndsa_valid/${odsCode}`;
  try {
    const result = await apiClient.get<{message: string, success: boolean}>(url);
    if (!result.success) {
      console.log(result.message);
    }
    return result.success;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateOrganisationUnitWithOdsCode = async (odsCode: string, ouId: number) => {
  const url = `organisation_units/${ouId}`;
  try {
    const body = {
      ods_code: odsCode,
    };
    return await apiClient.put(url, body);
  } catch (error) {
    return false;
  }
};

export const subscribeToCollection = async (advancedCollectionCodename: string, orgId: number, ouId: number) => {
  const url = `organisations/${orgId}/advanced_collections/subscribe`;
  try {
    const body = {
      ou_id: ouId,
      collection_codename: advancedCollectionCodename,
    };
    return await apiClient.put(url, body);
  } catch (error) {
    return false;
  }
};

export const unsubscribeFromCollection = async (advancedCollectionCodename: string, orgId: number, ouId: number) => {
  const url = `organisations/${orgId}/advanced_collections/unsubscribe`;
  try {
    const body = {
      ou_id: ouId,
      collection_codename: advancedCollectionCodename,
    };
    return await apiClient.put(url, body);
  } catch (error) {
    return false;
  }
};

export const asidExistsInLookup = async (odsCode: string, ouId: number) => {
  const url = `gp_connect/asid_exists/${odsCode}?ou=${ouId}`;
  try {
    return await apiClient.get<boolean>(url, {});
  } catch (error) {
    return false;
  }
};

export const lcrOdsCodeLookup = async (odsCode: string) => {
  const url = `london_care_record/ods_check/${odsCode}`;
  try {
    return await apiClient.get<{success: boolean, error?:string, data?: {
      shareCareRegionCode: string, odsCode: string
    }}>(url, {});
  } catch (error) {
    return { success: false };
  }
};

export const fetchTosFile = async (codename: string, tosUrl = ''): Promise<Blob|null> => {
  // TODO use apiClient and built-in headers handling
  const url = `api/v2/integrations/download_nourish_tos?integration_codename=${codename}&url=${tosUrl}`;
  try {
    const { token, ouId } = storeToRefs(nourishAuthStore());
    const headers = new Headers();
    if (token.value && ouId.value) {
      headers.append('x-api-key', token.value);
      headers.append('Organisation-Unit-Id', ouId.value.toString());
    }
    const value: null|string = window.localStorage.getItem('deviceUUID');
    if (value) {
      headers.append('X-Device-Uuid', value);
    }

    return fetch(url, {
      method: 'GET',
      headers,
    })
      .then((response) => response.blob())
      .catch((error) => {
        console.error('Error:', error);
        return null;
      });
  } catch (error) {
    console.log(error.response.data.error);
    return null;
  }
};
