
import { ref, defineComponent, watchEffect } from 'vue';
import use from '@/_shared/compositionApi';

export default defineComponent({
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    modelValue: {
      type: Number,
      default: 1,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const currentPage = ref(0);
    const { translate } = use.helpers();

    watchEffect(() => {
      currentPage.value = props.modelValue;
    });

    const prev = () => {
      currentPage.value -= 1;
      context.emit('update:modelValue', currentPage.value);
    };
    const next = () => {
      currentPage.value += 1;
      context.emit('update:modelValue', currentPage.value);
    };
    const setPage = (page: number) => {
      currentPage.value = page;
      context.emit('update:modelValue', currentPage.value);
    };
    const showPage = (page: number) => {
      const maximumPage = Math.ceil(currentPage.value / 10) * 10;
      const minimumPage = maximumPage - 9;
      return (page !== props.totalPages && page >= minimumPage && page <= maximumPage);
    };
    const extraPagesToRight = () => {
      if (props.totalPages < 11) { return false; }
      const currentSetOfPages = Math.ceil(currentPage.value / 10) - 1;
      const finalSetOfPages = Math.ceil(props.totalPages / 10) - 1;
      return props.totalPages > 11 && currentSetOfPages < finalSetOfPages;
    };
    const extraPagesToLeft = () => {
      if (props.totalPages === 1) { return false; }
      const currentSetOfPages = Math.ceil(currentPage.value / 10);
      return props.totalPages > 11 && currentSetOfPages > 1;
    };

    return {
      currentPage,
      prev,
      next,
      setPage,
      showPage,
      extraPagesToRight,
      extraPagesToLeft,
      translate,
    };
  },
});
