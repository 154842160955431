import ClientType from '@/client_types/types/client_type';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import { Option } from '@/_shared/types/baseSelect';

export const parseReactiveTextToStaticText = (value: string) => {
  if (!value) { return ''; }
  let converted = [];
  converted = value.split('');
  return converted.join('');
};

export const idToString = (object: ClientType) => {
  if (!object) { return ''; }
  return object.id ? object.id.toString() : 'newObject';
};

export const nameTaken = (clientType: ClientType, name: string, clientTypes: ClientType[]) => {
  if (clientTypes) {
    return clientTypes.filter((ct: ClientType) => formatName(ct.name!) === formatName(name) && ct.id !== clientType.id).length > 0;
  }
  return [];
};

export const formatName = (name: string) => (name ? name.toLowerCase() : '');

export const formatClientTypeName = (clientType: ClientType) => (clientType.name ? formatName(clientType.name) : '');

export const sortClientTypes = (clientTypes: ClientType[]) => {
  if (!clientTypes) { return []; }
  clientTypes.sort(sortClientTypesByVisible);
  clientTypes.sort(sortClientTypesByName);
  return clientTypes;
};
export const sortClientTypesByVisible = (ct1:ClientType, ct2:ClientType) => (formatClientTypeName(ct1) > formatClientTypeName(ct2) ? 1 : -1);
export const sortClientTypesByName = (ct1:ClientType, ct2:ClientType) => (ct1!.visible! > ct2!.visible! ? -1 : 1);

export const sortOrganisationUnitsOptions = (organisationUnits: OrganisationUnit[], visibleOuIds: number[]) => {
  if (organisationUnits.length > 0) {
    const options = [] as Option[];
    const filteredOrganisationUnits = organisationUnits.filter((ou: OrganisationUnit) => visibleOuIds.includes(ou.id!));
    filteredOrganisationUnits.sort((ou1: OrganisationUnit, ou2: OrganisationUnit) => {
      if (ou1.name && ou2.name) {
        return ou1.name > ou2.name ? 1 : -1;
      }
      return 0;
    });
    filteredOrganisationUnits.forEach((organisationUnit: OrganisationUnit) => {
      options.push({ value: organisationUnit.id, text: organisationUnit.name } as Option);
    });
    return options;
  }
  return [] as Option[];
};
