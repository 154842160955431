import {
  CoercedChoice,
  CoercedSingleSelect,
  Parameter,
  Parameters,
} from '@/timeline/types/Parameter';
import IInteraction from '@/timeline/types/IInteraction';
import { differenceInDays, format } from 'date-fns';
import use from '@/_shared/compositionApi';
import { InteractionLogGraphData, SeriesData } from '@/timeline/types/InteractionLogData';
import addPreferredTermsToText from '@/_shared/services/clientHelpers';

const { translate } = use.helpers();

function serviceLogGraph(interactions: IInteraction[]): InteractionLogGraphData[] {
  const a = graphableParameters(interactions);
  const b = a.map((parameter: Parameter, _, parameters: Parameter[]) => buildSeries(interactions, parameters, parameter));
  const c = b.filter((series: InteractionLogGraphData) => !!series);

  return c;
}

function graphableParameters(interactions: IInteraction[]) {
  const parameters: Parameters = {};
  interactions.forEach((interaction) => {
    interaction.parameters?.forEach((parameter) => {
      if (shouldSkipParameter(parameters, parameter)) {
        return;
      }
      parameters[parameter.id] = parameter;
      parameters[parameter.id].name = addPreferredTermsToText(interaction.clientId as number, parameters[parameter.id].name).value;
    });
    interaction.computedParameters?.forEach((parameter) => {
      if (shouldSkipParameter(parameters, parameter)) {
        return;
      }
      parameters[parameter.id] = parameter;
    });
  });
  return Object.values(parameters);
}

function shouldSkipParameter(parameters: Parameters, parameter: Parameter) {
  return parameters[parameter.id]
    || parameter.valueType !== 'number'
    || parameter.coercedValue === null;
}

function findParameterById(parameters: Parameter[], id: number) {
  return Object.values(parameters).find((param) => param.id === id);
}

function buildSeries(interactions: IInteraction[], parameters: Parameter[], parameter: Parameter) {
  const seriesData = buildSeriesData(interactions, parameter);

  return {
    name: parameterName(parameters, parameter),
    type: 'line',
    smooth: true,
    data: seriesData,
  };
}

function parameterName(parameters: Parameter[], parameter: Parameter) {
  let { name } = parameter;

  // Append ID
  parameters.forEach((p: Parameter) => {
    if (p.id !== parameter.id && p.name === parameter.name) {
      name = appendId(parameter.name, parameter.id as number);
    }
  });

  return formatName(parameter, name);
}

function appendId(name: string, id: number) {
  return `${name} (id: ${id})`;
}

function appendArchived(name: string) {
  return `${name} (${translate('common.archived')})`;
}

function formatName(parameter: Parameter, name: string) {
  if (parameter.unit) {
    name = `${name} (${parameter.unit})`;
  }
  if (parameter.archived) {
    name = appendArchived(parameter.name);
  }
  return name;
}

function buildSeriesData(interactions: IInteraction[], parameter: Parameter): SeriesData[] {
  const data: SeriesData[] = [];
  const shouldGroupValues = parameter.groupValuesInLogs;
  const firstInteractionFinishAt = new Date(interactions[0].finishAt as string);
  const lastInteractionFinishAt = new Date(interactions[interactions.length - 1].finishAt as string);
  const dayRange = differenceInDays(
    lastInteractionFinishAt,
    firstInteractionFinishAt,
  );

  interactions.forEach((interaction) => {
    let { parameters } = interaction;
    let groupingKey: Date;

    if (parameter.isComputed) {
      parameters = interaction.computedParameters || [];
    }
    const param = findParameterById(parameters as Parameter[], parameter.id as number);
    const scoreLabel = getScoreLabel(param as unknown as Parameter);
    const paramUnit = getParamUnit(param as unknown as Parameter);
    const value = param && primitiveValue(param as Parameter);
    if (value == null || value === false) { // accepts edge case where value = 0
      return;
    }

    if (!shouldGroupValues) {
      data.push([new Date(interaction.finishAt as string), value, scoreLabel, paramUnit]);
      return;
    }

    // The echarts library creates its own time scale on the x axis depending on the range
    // of values provided. e.g. if you set the range to be 1 month but there is only data on
    // days 1, 2, and 4 then it will set the x axis to 4 days rather than a month.
    // This is an attempt to group items roughly in line with their scale.

    if (dayRange > 5) {
      groupingKey = new Date(new Date(interaction.finishAt as string).setHours(0, 0, 0, 0));
    } else if (dayRange > 1) {
      groupingKey = new Date(new Date(interaction.finishAt as string).setMinutes(0, 0, 0));
    } else {
      groupingKey = new Date(interaction.finishAt as string);
    }

    data.push([groupingKey, value, scoreLabel, paramUnit]);
  });
  return data;
}

function getScoreLabel(param: Parameter) {
  const coercedValue = param && param.coercedValue as CoercedChoice;
  return coercedValue?.label || '';
}

function getParamUnit(param: Parameter) {
  return (param && param.unit) || '';
}

function primitiveValue(parameter: Parameter) {
  let value;
  if (hasMultipleChoices(parameter.coercedChoices)) {
    if (parameter.isArray) {
      const coercedValues = parameter.coercedValue as CoercedChoice[];
      value = coercedValues && coercedValues[0]
        && coercedValues[0].value;
    } else {
      const coercedValue = parameter.coercedValue as CoercedSingleSelect;
      value = coercedValue?.value;
    }
  } else if (parameter.isArray) {
    [value] = parameter.coercedValue as CoercedChoice[];
  } else {
    value = parameter.coercedValue;
  }
  return value;
}

function hasMultipleChoices(coercedChoices: CoercedChoice[]) {
  return coercedChoices.length > 0;
}

export const formatTimeAxis = (value: string) => {
  const date = new Date(value);
  return `${format(date, 'HH:mm')}\n${format(date, 'd MMM')}`;
};

export default serviceLogGraph;
