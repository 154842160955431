import {
  computed, ComputedRef,
} from 'vue';
import { dataPointsStore } from '@/_shared/store/dataPoints';
import useClientBadgesHelper from '@/_shared/helpers/useClientBadgesHelper';
import { HealthProfile } from '@/_shared/types/healthProfile';
import { useSingletonComputed } from '@/_shared/services/UseUtils';
import {
} from '@vueuse/core';
import { instancesStore } from '@/_shared/store/Instances';
import { Person, PersonQueryResponse, PersonStore } from './people';
import apiClient from '../services/apiClient';

export type Client = Person & {
  activeOrganisationUnitIds: number[]
  addressCaption: string,
  preferredName?: string
  firstName: string
  lastName: string
  clientType: string
  title?: string,
  birthDate?: string,
  sex?: string,
  observations?: string,
  organisationUnitIds?: number[]
  state?: string,
  stateInOrg?: string,
  startOn?: string,
  careplanReplicateClientId?: number,
  fromForm?: string
  healthProfile?: HealthProfile
}

export type ClientInformation = Client & {
  healthNumber: string
  birthDate: string
  integrationId: string
  errorMessages?: string[]
}

export interface ClientQueryResponse extends PersonQueryResponse {
  clients: Client[];
}

export interface ClientResponse {
  client: Client;
}

class ClientStore extends PersonStore<Client, ClientQueryResponse> {
  // Todo we can link datapoint store to it whene ever we initialize or fetch we can fetch datapoints
  fetch(ids: number[]): Promise<ClientQueryResponse> {
    const data = { query: { ids } };
    const urlPath = `/api/v2/clients/query?ou_id=${window.currentOrganisationUnit?.id}`
      + '&include_health_profile=true'
      + '&only=id,state_in_org,name,photo,preferred_name,first_name,last_name,client_type,health_profile,organisation_unit_ids,address_caption';
    return apiClient.post<ClientQueryResponse>(urlPath, data);
  }

  extractData(data: ClientQueryResponse): Client[] {
    return data?.clients;
  }

  preferredOrFirstName = (id: number): ComputedRef<string> => computed(() => this.byId(id).preferredName || this.byId(id).firstName);

  addressCaption = (id: number): ComputedRef<string> => computed(() => this.byId(id).addressCaption);

  fullName = (id: number): ComputedRef<string> => computed(() => {
    const { firstName } = this.byId(id);
    const { lastName } = this.byId(id);
    if (firstName) {
      if (lastName) {
        return `${firstName} ${lastName}`;
      }
      return firstName;
    }
    return lastName;
  });

  firstName = (id: number): ComputedRef<string> => computed(() => this.byId(id).firstName);

  clientType = (id: number) => this.byId(id).clientType;

  stateInOrg = (id: number) => this.byId(id).stateInOrg;

  healthProfile = (id: number) => this.byId(id).healthProfile;

  clients = (ids: number[]) => ids.map((id) => this.byId(id));

  badges = useSingletonComputed((id: number) => {
    const clientHealthProfile = clientStore.healthProfile(id);
    const dataPoints = dataPointsStore.clientDataPoints(id);
    return clientHealthProfile && dataPoints ? useClientBadgesHelper().getClientBadges(clientHealthProfile, dataPoints) : [];
  });

  currentOrgUnitState = useSingletonComputed((id: number) => (id ? (clientStore.byId(id)?.stateInOrg || '') : ''));

  skinInstances = useSingletonComputed((id: number) => instancesStore.skinInstancesByClientId(id) || []);

  eventInstances = useSingletonComputed((id: number) => instancesStore.eventInstancesByClientId(id) || []);

  reFetchInstances = (id: number) => instancesStore.reFetchById(id);

  clientTerm = (isLowercase = true) => {
    const clientTerm = window?.currentOrganisationUnit?.client_term_plural || 'Residents';
    return isLowercase ? clientTerm.toLowerCase() : clientTerm;
  };

  orgUnits = (id: number) => this.byId(id).organisationUnitIds;
}

export const clientStore: ClientStore = new ClientStore();
